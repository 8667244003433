import React from "react"
import styled from "styled-components"
import "@fontsource/eb-garamond"
import { StaticImage } from "gatsby-plugin-image"

const TourEnd = () => {
  return (
    <SectionContainer id="tourend">
      <SectionWrapper>
        <ColumnTwo>
          <StaticImage src="../../images/Section3.png" alt="Sec3" />
        </ColumnTwo>
        <ColumnOne>
          <SectionH1>BUILT FOR</SectionH1>
          <SectionH2>THE FUTURE</SectionH2>
          <SectionP>
            ZAJIYAH Carnival ushers Pakistan into the new age of construction.
            <br />
            Incorporating true BIM processes throughout the design,
            construction, construction management and building management
            lifecycle, the Carnival will be the first retail project in Pakistan
            to adopt these cutting-edge international practices from the get-go.
            <br />
            The result will be a high-efficiency, low-energy consumption
            masterpiece of world-class engineering architecture that will
            revolutionize the future of construction in Pakistan.
          </SectionP>
        </ColumnOne>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default TourEnd

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  padding: 6rem 0rem;

  margin: auto;

  @media screen and (max-width: 960px) {
    margin-top: -80px;
  }
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1 1rem;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`
const ColumnOne = styled.div`
  padding: 0 1rem;
  order: 2;
  @media screen and (max-width: 960px) {
    padding: 0 1rem;
    float: left;
    order: 1;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 1rem;
  margin-top: -160px;
  order: 1;

  @media screen and (max-width: 960px) {
    padding: 0 1rem;
    float: right;
    order: 2;
    margin-top: 0px;
  }
`
const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: justify;
  letter-spacing: 6px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-size: 3rem;
  }
`
const SectionH2 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: justify;
  letter-spacing: 1px;
  margin-top: -30px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-size: 3rem;
  }
`

const SectionP = styled.p`
  color: #545454;
  font-size: 1.3rem;
  text-align: justify;
  font-family: "EB Garamond";
  line-height: 1.7;
  font-weight: 500;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`
