import React, { useState } from "react"
import Footer from "../components/Layout Components/Footer"
import Navbar from "../components/Layout Components/Navbar"
import Sidebar from "../components/Layout Components/Sidebar"
import Hero from "../components/Sections/Hero"
import TourStart from "../components/Sections/TourStart"
import TourEnd from "../components/Sections/TourEnd"
import FeaturesStart from "../components/Sections/FeaturesStart"
import FeaturesEnd from "../components/Sections/FeaturesEnd"
import Updates from "../components/Sections/Updates"
import Partners from "../components/Sections/Partners"
import { createGlobalStyle } from "styled-components"
import Seo from "../components/seo"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <head>
        <title>Home | CARNIVAL</title>
        <meta
          name="description"
          content="ZAJIYAH Carnival is the First Smart Brand City of Pakistan. It is a one of its kind commercial real estate project."
        />
        <link rel="icon" href="/Logo Small.png" />
      </head>

      <main></main>
      <Seo title="Home" />
      <GlobalStyle />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <TourStart />
      <TourEnd />
      <FeaturesStart />
      <FeaturesEnd />
      <Updates />
      <Partners />
      <Footer />
    </>
  )
}

export default HomePage
