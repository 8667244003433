import React from "react"
import styled from "styled-components"
import "@fontsource/eb-garamond"
import { StaticImage } from "gatsby-plugin-image"

const TourStart = () => {
  return (
    <SectionContainer id="tourstart">
      <SectionWrapper>
        <ColumnOne>
          <SectionH1>CONCEPT</SectionH1>
          <SectionH2>MARVEL</SectionH2>
          <SectionP>
            The perfect blend of Retail, Entertainment, Business and Leisure.
            <br />
            The Carnival is designed to meet the high-paced needs of the modern
            urban population. In a world where time is a precious commodity, the
            Carnival offers the opportunity to Shop, Relax, Work and Enjoy all
            at the same place. Filled with Retail stores, Restaurants, Offices,
            Leisure and Entertainment Centers. The Carnival is a Modern
            Conglomerate that brings together the best of everything enveloped
            in Majestic Architecture.
          </SectionP>
        </ColumnOne>
        <ColumnTwo>
          <StaticImage src="../../images/Section2.png" alt="Sec2" />
        </ColumnTwo>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default TourStart

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  padding: 6rem 0rem;

  margin: auto;
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1 1rem;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`
const ColumnOne = styled.div`
  padding: 0 1rem;
  @media screen and (max-width: 960px) {
    padding: 0 1rem;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 1rem;

  @media screen and (max-width: 960px) {
    padding: 0 1rem;
  }
`

const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: justify;
  letter-spacing: 1px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-size: 3rem;
  }
`
const SectionH2 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: justify;
  letter-spacing: 8px;
  margin-top: -30px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-size: 3rem;
  }
`

const SectionP = styled.p`
  color: #545454;
  font-size: 1.3rem;
  text-align: justify;
  font-family: "EB Garamond";
  line-height: 1.7;
  font-weight: 500;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
`
