import React from "react"
import styled from "styled-components"
import { Link as LinkS } from "react-scroll"
import "@fontsource/cinzel"
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa"
import { AiFillInstagram } from "react-icons/ai"
import { IoLogoWhatsapp } from "react-icons/io"
import BG1 from "../../images/BG1.png"

const Hero = () => {
  return (
    <HeroContainer id="about">
      <Social>
        <a
          target="_blank"
          href="https://www.facebook.com/ZAJIYAHCARNIVAL"
          rel="noopener noreferrer"
        >
          <SocialIcon>
            <FaFacebook />
          </SocialIcon>
        </a>

        <a
          href="https://www.instagram.com/zajiyah_carnival/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon>
            <AiFillInstagram />
          </SocialIcon>
        </a>

        <a
          href="https://www.linkedin.com/in/zajiyah-carnival-b10052210/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon>
            <FaLinkedin />
          </SocialIcon>
        </a>

        <a
          href="https://twitter.com/ZajiyahC"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon>
            <FaTwitter />
          </SocialIcon>
        </a>

        <a
          href="https://www.instagram.com/zajiyah_carnival/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon>
            <FaYoutube />
          </SocialIcon>
        </a>

        <a
          href="https://api.whatsapp.com/send?phone=923070111307"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon>
            <IoLogoWhatsapp />
          </SocialIcon>
        </a>
      </Social>
      <HeroContent>
        <HeroItems>
          <HeroH1>FIRST SMART BRAND CITY OF PAKISTAN</HeroH1>
          <NavBtn>
            <NavBtnLink
              to="tourstart"
              smooth={true}
              duration={1500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Give me a Tour
            </NavBtnLink>
          </NavBtn>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
  background: url(${BG1}) center center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  position: relative;
  margin-top: -80px;
  color: white;
  flex-direction: column;
  object-fit: contain;
  width: 100%;
`

const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1400px) / 2);

  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
`
const HeroItems = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: white;
  line-height: 1.1;
  top: 455px;
  right: 65px;
  position: absolute;

  @media screen and (max-width: 900px) {
    position: relative;
    align-items: center;
    right: 0;
    padding: 0 1rem;
  }
`
const HeroH1 = styled.h1`
  font-family: "Cinzel", serif;
  position: relative;
  font-weight: 300;
  font-size: 24px;
  display: flex;
  margin-bottom: 10px;

  border-bottom: 2px solid #f1c057;
`

const Social = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 4;
  font-size: 1.7rem;
  margin-top: 33rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;

  a {
    text-decoration: none;
    color: white;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`
const SocialIcon = styled.div`
  &:hover {
    color: #fabf49;
  }
`

const NavBtn = styled.div`
  display: flex;
  align-items: center;
`
const NavBtnLink = styled(LinkS)`
  background: transparent;
  white-space: nowrap;
  padding: 15px 25px;
  color: white;
  border: 1px solid white;
  min-width: 100px;
  font-size: 16px;
  font-family: "Aileron", sans-serif;
  cursor: pointer;
  text-decoration: none;

  scroll-behavior: smooth;

  transition: 0.8s !important;
  border-radius: 20px;

  &:hover {
    background: white;
    color: #e1ae44;
    transform: translateY(-2px);
    box-shadow: 0px 8px 15px rgba(225, 174, 68, 0.2);
  }
`
