import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticImage as Image } from "gatsby-plugin-image"

const Partners = () => {
  return (
    <SectionContainer id="partners">
      <SectionWrapper>
        <ColumnOne>
          <H1Wrap>
            <SectionH1>PARTNERS</SectionH1>
          </H1Wrap>
        </ColumnOne>
        <ColumnTwo>
          <LogoContainer>
            <Link to="/partners">
              <Image src="../../images/L1.png" alt="L1" />
            </Link>
          </LogoContainer>
          <LogoContainer>
            <Link to="/partners">
              <Image src="../../images/L2.png" alt="L2" />
            </Link>
          </LogoContainer>
          <LogoContainer>
            <Link to="/partners">
              <Image src="../../images/L3.png" alt="L3" />
            </Link>
          </LogoContainer>
        </ColumnTwo>
        <ColumnThree>
          <LogoContainer1>
            <Link to="/partners">
              <Image src="../../images/L4.png" alt="L4" />
            </Link>
          </LogoContainer1>
          <LogoContainer2>
            <Link to="/partners">
              <Image src="../../images/L5.png" alt="L5" />
            </Link>
          </LogoContainer2>
        </ColumnThree>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default Partners

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  margin-bottom: 6rem;
  align-items: center;

  @media screen and (max-width: 1100px) {
    margin-top: 0px;
  }
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;

  grid-gap: 2.5rem;

  @media screen and (max-width: 1100px) {
    grid-template-rows: 1fr;
  }
`
const ColumnOne = styled.div`
  display: flex;

  @media screen and (max-width: 1100px) {
    margin-top: 0px;
    display: flex;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 4rem 0.2rem;
  column-gap: 1rem;

  @media screen and (max-width: 1100px) {
    margin: 0 1rem;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    margin: 0 1rem;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
`
const ColumnThree = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1 0.2rem;
  column-gap: 1rem;

  @media screen and (max-width: 1100px) {
    margin: 0 1rem;
  }
  @media screen and (max-width: 768px) {
    margin: 0 1rem;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
`

const LogoContainer = styled.div`
  display: flex;
  color: white;

  align-items: center;
  justify-content: center;

  padding: 0 0rem;
  height: 100%;
  width: 100%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1100px) {
    margin: 0 1rem;
  }
  @media screen and (max-width: 768px) {
    margin: 0 1rem;
  }
`
const LogoContainer1 = styled.div`
  display: flex;
  color: white;
  margin-left: 200px;
  align-items: center;
  justify-content: center;

  padding: 0 3rem;
  height: 100%;
  width: 100%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1100px) {
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
`
const LogoContainer2 = styled.div`
  display: flex;
  color: white;
  margin-right: 900px;
  align-items: center;
  justify-content: center;

  padding: 0 0rem;
  height: 100%;
  width: 100%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1100px) {
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
`
const H1Wrap = styled.div`
  background: #1b2227;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
`

const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #fabf49;

  text-align: center;
  letter-spacing: 3px;

  @media screen and (max-width: 1100px) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
`
