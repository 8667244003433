import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link as LinkS } from "react-scroll"
import { StaticImage } from "gatsby-plugin-image"
import { FaBars } from "react-icons/fa"
import "@fontsource/encode-sans-expanded"
import { Link } from "gatsby"

const Navbar = ({ toggle }) => {
  const [scroll, setScroll] = useState(false)

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useEffect(() => {
    changeNav()
    window.addEventListener("scroll", changeNav)
  }, [])

  return (
    <>
      <Nav active={scroll}>
        <NavbarContainer>
          <NavLogo
            to="about"
            smooth={true}
            duration={1500}
            spy={true}
            exact="true"
          >
            <NavIcon>
              <StaticImage
                src="../../images/Icon.png"
                width={90}
                height={90}
                alt="Icon"
                quality={100}
              />
            </NavIcon>
          </NavLogo>

          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink
                to="about"
                smooth={true}
                duration={1500}
                spy={true}
                exact="true"
                activeClass="active"
              >
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="featuresstart"
                smooth={true}
                duration={1500}
                spy={true}
                exact="true"
                offset={-80}
                activeClass="active"
              >
                Features
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="updates"
                smooth={true}
                duration={1500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Updates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="partners"
                smooth={true}
                duration={1500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Partners
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink2 to="/careers">Careers</NavLink2>
            </NavItem>
            <NavItem>
              <NavLink2 to="/contact">Contact Us</NavLink2>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to="/contact">BOOK NOW</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar

const Nav = styled.nav`
  background: ${({ active }) => (active ? "#1b2227" : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 900px) {
    transition: 0.8s all ease;
    position: sticky;
    background: ${({ active }) => (active ? "#1b2227" : "transparent")};
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    top: 0;
    z-index: 11;
  }
`

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 11;
  width: 100%;
  padding: 0 24px;
  max-width: 1160px;
`

const NavLogo = styled(LinkS)`
  justify-self: flex-start;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin-left: 24px;

  text-decoration: none;
`
const NavIcon = styled.div``

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fabf49;
  }
`

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-left: -24px;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;

    height: 90vh;
    position: absolute;
    top: ${({ click }) => (click ? "100%" : "-1000px")};
    opacity: 1;
    transition: all 0.2s ease;
    background: #1b2227;
    color: #fabf49;
    left: 0;
  }
`

const NavItem = styled.li`
  height: 80px;

  @media screen and (max-width: 900px) {
    width: auto;
  }
`
const NavLink = styled(LinkS)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: "Encode Sans Expanded", sans-serif;
  font-size: 14px;

  &.active {
    border-bottom: 3px solid #fabf49;
  }

  &:hover {
    color: #fabf49;
  }

  @media screen and (max-width: 900px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fabf49;

    &.active {
      border: none;
    }

    &:hover {
      color: white;
      transition: all 0.3s ease;
    }
  }
`

const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

const NavBtnLink = styled(Link)`
  border-radius: 50px;
  background: #fabf49;
  white-space: nowrap;
  padding: 10px 20px;
  color: black;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-family: "Encode Sans Expanded", sans-serif;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: white;
    color: #fabf49;
    transform: translateY(-2px);
    box-shadow: 0px 8px 15px rgba(225, 174, 68, 0.2);
  }
`

const NavLink2 = styled(Link)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: "Encode Sans Expanded", sans-serif;
  font-size: 14px;

  &.active {
    border-bottom: 3px solid #fabf49;
  }

  &:hover {
    color: #fabf49;
  }

  @media screen and (max-width: 900px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fabf49;

    &.active {
      border: none;
    }

    &:hover {
      color: white;
      transition: all 0.3s ease;
    }
  }
`
