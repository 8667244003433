import React from "react"
import styled from "styled-components"
import { StaticImage as Image } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Updates = () => {
  return (
    <SectionContainer id="updates">
      <SectionWrapper>
        <ColumnOne>
          <SectionH1>PROGRESS</SectionH1>
        </ColumnOne>
        <ColumnTwo>
          <Link to="/updates">
            <Image src="../../images/I10.png" alt="I10" />
          </Link>
          <Link to="/updates">
            <Image src="../../images/I11.png" alt="I11" />
          </Link>
        </ColumnTwo>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default Updates

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  padding: 3rem 0rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 960px) {
    margin-top: 0px;
  }
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  padding: 1 1rem;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-rows: 1fr;
  }
`
const ColumnOne = styled.div`
  padding: 0 0.2rem;

  @media screen and (max-width: 960px) {
    padding: 0 1rem;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1 0.2rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background: white;
    cursor: pointer;
    color: #e1ae44;
    transform: translateY(-2px);
    box-shadow: 0px 10px 15px rgba(225, 174, 68, 0.6);
    border: 1px solid #e1ae44;
  }

  @media screen and (max-width: 960px) {
    margin: 0 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    margin: 0 1rem;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
`
const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: center;
  letter-spacing: 3px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-size: 3rem;
  }
`
